import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Event from '../components/Event';
import baseURL from '../components/config';

function Events() {
    const [isAdmin, setIsAdmin] = useState(false);
    const [topEvents, setTopEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [adminEvents, setAdminEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    // const [myEvents, setMyEvents] = useState([]);
    const navigate = useNavigate();
    

    useEffect(() => {
        const localIsAdmin = localStorage.getItem('isAdmin');
        localIsAdmin === null || localIsAdmin === 'false' ? setIsAdmin(false) : setIsAdmin(true);

        fetchEventsAndJoinStatus();     
    }, []);

    // Fetch all events that are created by current user
    // const fetchMyEvents = async () => {
    //     try {
    //         const response = await axios.get('${baseURL}/api/events/my');
    //         // console.log(response.data)
    //         setEvents(response.data.events);
    //     } catch (error) {
    //         console.error('Error fetching events:', error);
    //     }
    // };

    const fetchEventsAndJoinStatus = async () => {
        try {
            const response = await axios.post(`${baseURL}/api/events`, {userID: localStorage.getItem('userID')});
            const eventsData = response.data;
    
            // Extract joined events from the response data
            const { topEvent, events, adminEvents, joinedEvents } = eventsData;
            console.log(eventsData)

            // Mark events and admin events as joined if they exist in joinedEvents
            const eventsWithJoinStatus = events.map(event => ({
                ...event,
                isJoined: joinedEvents.some(joinedEvent => joinedEvent.eventID === event.eventID)
            }));
    
            const adminEventsWithJoinStatus = adminEvents.map(adminEvent => ({
                ...adminEvent,
                isJoined: joinedEvents.some(joinedEvent => joinedEvent.eventID === adminEvent.eventID)
            }));
            
            setEvents(eventsWithJoinStatus);
            setAdminEvents(adminEventsWithJoinStatus);

            // Update state with events and admin events including join status
            // console.log(topEvents)
            if (topEvent) {
                // Mark topevent as joined if it exist in joinedEvents
                joinedEvents.some(joinedEvent => joinedEvent.eventID === topEvent.eventID) ? topEvent["isJoined"] = true : topEvent.isJoined = false; 
                setTopEvents([topEvent]);
                // console.log(topEvents)
                setAllEvents([topEvent, ...adminEvents, ...events])
                console.log(allEvents.length)
            } else {
                setAllEvents([...adminEvents, ...events])
                // console.log(allEvents)
            }
        } catch (error) {
            console.error('Error fetching events and join status:', error);
        }
    };

    const handleCreateEvent = async () => {
        if (!localStorage.getItem('jwtToken')) {
            alert('Please log in first')
            navigate('/login');
        } else {
            // Check if user has filled all required information in profile
            try {
                // Get JWT token from local storage
                const jwtToken = localStorage.getItem('jwtToken');
                // console.log(jwtToken);

                const response = await fetch(`${baseURL}/api/personal_detail/get`, {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`
                    }
                });

                const data = await response.json();

                // console.log(
                // !data[0].expectSalary ||
                // !data[0].chosenCities ||
                // !data[0].chosenEmployers);
                
                // Check if any required information is missing
                if (!data[0].Email ||
                    !data[0].userName ||
                    !data[0].fullName ||
                    !data[0].phoneNumber ||
                    !data[0].nationality ||
                    !data[0].gender ||
                    !data[0].DOB ||
                    !data[0].WeChatID ||
                    !data[0].graduateSchool ||
                    !data[0].degreeInfo ||
                    !data[0].courseMajor ||
                    !data[0].faculty || data[0].faculty === 'None' ||
                    !data[0].advisor ||
                    !data[0].graduateTime ||
                    !data[0].researchAchievement ||
                    !data[0].expectSalary ||
                    !data[0].chosenCities ||
                    !data[0].chosenEmployers
                ) {
                    // If any required information is missing, show alert and navigate to profile page
                    alert("Please fill your profile before creating an event");
                    localStorage.setItem('isEditing', true); // make isEditing true for profile page
                    navigate('/profile'); // Navigate to profile page
                    return; // Stop execution
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }

            navigate('/create-event');
        }
    };

    return (
        <div>
            <main className="pc-main">
                <section className="event-section">
                    <div className="event-main-section">
                        <div className="create-event-btn">
                            <h1>Events</h1>
                            <button className='cta-button' onClick={handleCreateEvent}>Create Event</button>
                        </div>
                        {/* {console.log(allEvents)} */}
                        {
                            isAdmin ? 
                            <>
                                {
                                    allEvents.length > 0 ?
                                    allEvents.map(event => <Event key={event.eventID} eventID={event.eventID} eventName={event.eventName} ownerID={event.ownerID} description={event.description} deadline={event.deadline} approved={event.status} eventIsAdmin={event.isAdmin} isJoined={event.isJoined} fetchEventsAndJoinStatus={fetchEventsAndJoinStatus} topEventID={topEvents[0] ? topEvents[0].eventID : null}/>)
                                    :
                                    <div className='no-event-text'>There are no events yet!</div>
                                }
                            </>
                            :
                            <>
                                {
                                    allEvents.length > 0 ?
                                    allEvents.filter(event => event.status !== 0).map(event => <Event key={event.eventID} eventID={event.eventID} eventName={event.eventName} ownerID={event.ownerID} description={event.description} deadline={event.deadline} approved={event.status} eventIsAdmin={event.isAdmin} isJoined={event.isJoined} fetchEventsAndJoinStatus={fetchEventsAndJoinStatus} topEventID={topEvents[0] ? topEvents[0].eventID : null}/>)
                                    :
                                    <div className='no-event-text'>There are no events yet!</div>
                                }
                            </>
                        }
                    </div>
                </section>
            </main>

            <main className="mobile-main">
                <section className="event-section">
                    <div className="event-main-section">
                        <div className="create-event-btn">
                            <h1>Events</h1>
                            <button className='cta-button' id='create-btn' onClick={handleCreateEvent}>Create Event</button>
                        </div>
                        {
                            isAdmin ? 
                            <>
                                {
                                    allEvents.length > 0 ?
                                    allEvents.map(event => <Event key={event.eventID} eventID={event.eventID} eventName={event.eventName} ownerID={event.ownerID} description={event.description} deadline={event.deadline} approved={event.status} eventIsAdmin={event.isAdmin} isJoined={event.isJoined} fetchEventsAndJoinStatus={fetchEventsAndJoinStatus} topEventID={topEvents[0] ? topEvents[0].eventID : null}/>)
                                    :
                                    <div className='no-event-text'>There are no events yet!</div>
                                }
                            </>
                            :
                            <>
                                {
                                    allEvents.length > 0  ?
                                    allEvents.filter(event => event.status !== 0).map(event => <Event key={event.eventID} eventID={event.eventID} eventName={event.eventName} ownerID={event.ownerID} description={event.description} deadline={event.deadline} approved={event.status} eventIsAdmin={event.isAdmin} isJoined={event.isJoined} fetchEventsAndJoinStatus={fetchEventsAndJoinStatus} topEventID={topEvents[0] ? topEvents[0].eventID : null}/>)
                                    :
                                    <div className='no-event-text'>There are no events yet!</div>
                                }
                            </>
                        }
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Events;
