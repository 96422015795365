import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { pca } from 'area-data';
import 'react-area-linkage/dist/index.css';
import axios from 'axios';
import WithAuthCheck from '../components/withAuthCheck';
import baseURL from '../components/config';
import MultiSelectCity from '../components/MultiSelectCity';
import {jwtDecode} from 'jwt-decode';
import MultiSelectEmployer from '../components/MultiSelectEmployer';

const Profile = () => {
    // Access the userId parameter from the URL
    const { ID } = useParams();
    const navigate = useNavigate();

    const [isAdmin, setIsAdmin] = useState(false);
    const [userID, setUserID] = useState(0);
    const [userName, setUserName] = useState("");

    const [fullName, setFullName] = useState("");
    const [Email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [nationality, setNationality] = useState("");
    const [gender, setGender] = useState("");
    const [DOB, setDOB] = useState("0000-00-00");
    const [WeChatID, setWeChatID] = useState("");

    const [graduateSchool, setGraduateSchool] = useState("");
    const [degreeInfo, setDegreeInfo] = useState("");
    const [courseMajor, setCourseMajor] = useState("");
    const [faculty, setFaculty] = useState("");
    const [advisor, setAdvisor] = useState("");
    const [graduateTime, setGraduateTime] = useState("0000-00-00");
    const [researchAchievement, setResearchAchievement] = useState("");


    const [expectEmployer, setExpectEmployer] = useState("");
    const [otherEmployer, setOtherEmployer] = useState("");
    const [expectSalary, setExpectSalary] = useState("");
    const [needHelp, setNeedHelp] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    
    const [isEditing, setIsEditing] = useState(false);

    const [chosenCities, setChosenCities] = useState(null);
    const [chosenEmployers, setChosenEmployers] = useState(null);

    const [NameErrorMsg, setNameErrorMsg] = useState('');
    const [isUserNameAvailable, setIsUserNameAvailable] = useState(true);

    const [formData, setFormData] = useState({
        userName: userName,
        fullName: fullName,
        phoneNumber: phoneNumber,
        nationality: nationality,
        gender: gender,
        DOB: DOB,
        WeChatID: WeChatID,
        graduateSchool: graduateSchool,
        degreeInfo: degreeInfo,
        courseMajor: courseMajor,
        faculty: faculty,
        advisor: advisor,
        graduateTime: graduateTime,
        researchAchievement: researchAchievement,
        expectSalary: expectSalary,
        needHelp: needHelp ? 1 : 0,
        Email: Email,
        chosenCities: chosenCities,
        chosenEmployers: chosenEmployers,
    });

    const maxLengths = {
        userName: 30,
        fullName: 30,
        Email: 50,
        phoneNumber: 30,
        nationality: 30,
        WeChatID: 50,
        graduateSchool: 30,
        degreeInfo: 30,
        courseMajor: 30,
        faculty: 30,
        advisor: 30,
        researchAchievement: 150,
        otherEmployer: 50
    };
    
    const handleTimeChange = (e) => {
        if (e.target.name === "DOB") {
            setDOB(e.target.value);
        } else {
            setGraduateTime(e.target.value); // Update the state when the date is selected
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFaculty = (e) => {
        setFaculty(e.target.value);
        setFormData({ ...formData, 'faculty': e.target.value });
    }
    
    const handlePhoneChange = (e) => {
        const input = e.target.value;
        const numericInput = input.replace(/\D/g, '');
        setPhoneNumber(numericInput);
        setFormData({ ...formData, 'phoneNumber': numericInput });
    }

    const handleGender = (e) => {
        setGender(e.target.value);
        setFormData({ ...formData, 'gender': e.target.value });
    }

    const handleNationality = (e) => {
        setNationality(e.target.value);
        setFormData({ ...formData, 'nationality': e.target.value });
    }

    const handleSalary = (e) => {
        setExpectSalary(e.target.value);
        setFormData({ ...formData, 'expectSalary': e.target.value });
    }

    const handleNeedHelpChange = (e) => {
        setNeedHelp(e.target.value);
        setFormData({ ...formData, 'needHelp': e.target.value });
    };

    const handleChange = (e) => {
        setIsUserNameAvailable(true);
        setFormData({ ...formData, [e.target.name]: e.target.value })
        // console.log(formData);
    };

    const handleEdit = () => {
        if (ID) {
            // Check if the current user is agent or admin
            if (localStorage.getItem("isAgent") == "true") {
                alert("Only admin user have permission to edit")
            } else if (localStorage.getItem("isAdmin") == "true") {
                setIsEditing(true);
            }
        } else {
            setIsEditing(true);
        }
    }

    const handleSave = async (e) => {
        if (expectEmployer === "其他" && formData.otherEmployer.length === 0) {
            alert("Please specify for other expected employer")
        } else {
            // Check if userName is available
            const response2 = await axios.post(`${baseURL}/api/check_user_name_availability/profile`, { userName: formData.userName, userID: userID });
            if (!response2.data.available) {
                alert("User name is already taken, please choose another name");
                setIsUserNameAvailable(false);
                setNameErrorMsg('User name is already taken');
                return;
            }

            setIsEditing(false);
            // console.log(formData)
            try {
                const response = await axios.post(`${baseURL}/api/personal_detail/save`, formData);
                // console.log(response.data);
                // console.log(formData);
                alert("Profile Updated Successfully")
                if (localStorage.getItem('isEditing') === 'true') {
                    localStorage.setItem('isEditing', 'false');
                    // navigate back to events page if user is requested to fill profile
                    navigate('/events')
                }
            } catch (error) {
                console.error('Error editing profile:', error);
            }
        }
    };

    // fetch the data that belongs to the passed in ID
    const fetchDataByID = async (e) => {
        try {
            // Get JWT token from local storage
            const jwtToken = localStorage.getItem('jwtToken');
            // console.log(jwtToken);

            const response = await fetch(`${baseURL}/api/personal_detail/get/admin`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${jwtToken}`
                },
                body: JSON.stringify(ID)
            });
            const data = await response.json();
            // console.log(data);
            
            // fetch user's detail
            data[0].Email ? setEmail(data[0].Email) : setEmail("");
            data[0].userID ? setUserID(data[0].userID) : setUserID(0);
            data[0].userName ? setUserName(data[0].userName) : setUserName("");
            data[0].fullName ? setFullName(data[0].fullName) : setFullName("");
            data[0].phoneNumber ? setPhoneNumber(data[0].phoneNumber) : setPhoneNumber("");
            data[0].nationality ? setNationality(data[0].nationality) : setNationality("");
            data[0].gender ? setGender(data[0].gender) : setGender("");
            data[0].DOB ? setDOB(data[0].DOB) : setDOB("0000-00-00");
            data[0].WeChatID ? setWeChatID(data[0].WeChatID) : setWeChatID("");
            data[0].graduateSchool ? setGraduateSchool(data[0].graduateSchool) : setGraduateSchool("");
            data[0].degreeInfo ? setDegreeInfo(data[0].degreeInfo) : setDegreeInfo("");
            data[0].courseMajor ? setCourseMajor(data[0].courseMajor) : setCourseMajor("");
            data[0].faculty ? setFaculty(data[0].faculty) : setFaculty("");
            data[0].advisor ? setAdvisor(data[0].advisor) : setAdvisor("");
            data[0].graduateTime ? setGraduateTime(data[0].graduateTime) : setGraduateTime("0000-00-00");
            data[0].researchAchievement ? setResearchAchievement(data[0].researchAchievement) : setResearchAchievement("");
            data[0].expectSalary ? setExpectSalary(data[0].expectSalary) : setExpectSalary("");
            data[0].needHelp === null|| data[0].needHelp === 0 ? setNeedHelp(false) : setNeedHelp(true);
            data[0].isAdmin ===  null || data[0].isAdmin === 0 ? setIsAdmin(false) : setIsAdmin(true);
            data[0].isAgent === null || data[0].isAgent === 0 ? setIsAgent(false) : setIsAgent(true);
            data[0].chosenCities ? setChosenCities(data[0].chosenCities) : setChosenCities([])
            data[0].chosenEmployers ? setChosenEmployers(data[0].chosenEmployers) : setChosenEmployers([])

            //  Syncrynasing Data
            setFormData({
                ...formData,
                Email: data[0].Email,
                userName: data[0].userName,
                fullName: data[0].fullName,
                phoneNumber: data[0].phoneNumber,
                nationality: data[0].nationality,
                gender: data[0].gender,
                DOB: data[0].DOB,
                WeChatID: data[0].WeChatID,
                graduateSchool: data[0].graduateSchool,
                degreeInfo: data[0].degreeInfo,
                courseMajor: data[0].courseMajor,
                faculty: data[0].faculty,
                advisor: data[0].dvisor,
                graduateTime: data[0].graduateTime,
                researchAchievement: data[0].researchAchievement,
                expectSalary: data[0].expectSalary,
                needHelp: data[0].needHelp ? 1 : 0,
                chosenCities: data[0].chosenCities,
                chosenEmployers: data[0].chosenEmployers,
            });
            // console.log(data[0].chosenEmployers);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    }

    // fetch the current logged in user's data
    const fetchData = async (e) => {
        // console.log(localStorage)
        try {
            // Get JWT token from local storage
            const jwtToken = localStorage.getItem('jwtToken');
            // console.log(jwtToken);

            const response = await fetch(`${baseURL}/api/personal_detail/get`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${jwtToken}`
                }
            });

            const data = await response.json();
            // console.log(data);
            
            // fetch user's detail
            data[0].Email ? setEmail(data[0].Email) : setEmail("");
            data[0].userID ? setUserID(data[0].userID) : setUserID(0);
            data[0].userName ? setUserName(data[0].userName) : setUserName("");
            data[0].fullName ? setFullName(data[0].fullName) : setFullName("");
            data[0].phoneNumber ? setPhoneNumber(data[0].phoneNumber) : setPhoneNumber("");
            data[0].nationality ? setNationality(data[0].nationality) : setNationality("");
            data[0].gender ? setGender(data[0].gender) : setGender("");
            data[0].DOB ? setDOB(data[0].DOB) : setDOB("0000-00-00");
            data[0].WeChatID ? setWeChatID(data[0].WeChatID) : setWeChatID("");
            data[0].graduateSchool ? setGraduateSchool(data[0].graduateSchool) : setGraduateSchool("");
            data[0].degreeInfo ? setDegreeInfo(data[0].degreeInfo) : setDegreeInfo("");
            data[0].courseMajor ? setCourseMajor(data[0].courseMajor) : setCourseMajor("");
            data[0].faculty ? setFaculty(data[0].faculty) : setFaculty("");
            data[0].advisor ? setAdvisor(data[0].advisor) : setAdvisor("");
            data[0].graduateTime ? setGraduateTime(data[0].graduateTime) : setGraduateTime("0000-00-00");
            data[0].researchAchievement ? setResearchAchievement(data[0].researchAchievement) : setResearchAchievement("");
            data[0].expectSalary ? setExpectSalary(data[0].expectSalary) : setExpectSalary("");
            data[0].needHelp ===  null || data[0].needHelp === 0 ? setNeedHelp(false) : setNeedHelp(true);
            data[0].isAdmin ===  null || data[0].isAdmin === 0 ? setIsAdmin(false) : setIsAdmin(true);
            data[0].isAgent === null || data[0].isAgent === 0 ? setIsAgent(false) : setIsAgent(true);
            data[0].chosenCities ? setChosenCities(data[0].chosenCities) : setChosenCities([])
            data[0].chosenEmployers ? setChosenEmployers(data[0].chosenEmployers) : setChosenEmployers([])
            
            // console.log(data[0].isAdmin ===  1);
            // console.log(isAdmin)

            //  Syncrynasing Data
            setFormData({
                ...formData,
                Email: data[0].Email,
                userName: data[0].userName,
                fullName: data[0].fullName,
                phoneNumber: data[0].phoneNumber,
                nationality: data[0].nationality,
                gender: data[0].gender,
                DOB: data[0].DOB,
                WeChatID: data[0].WeChatID,
                graduateSchool: data[0].graduateSchool,
                degreeInfo: data[0].degreeInfo,
                courseMajor: data[0].courseMajor,
                faculty: data[0].faculty,
                advisor: data[0].advisor,
                graduateTime: data[0].graduateTime,
                researchAchievement: data[0].researchAchievement,
                expectSalary:data[0].expectSalary,
                needHelp: data[0].needHelp ? 1 : 0,
                chosenCities: data[0].chosenCities,
                chosenEmployers: data[0].chosenEmployers,
            });
            // console.log(data[0].chosenCities);
            // console.log(data[0].chosenEmployers);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    }

    // For exporting a user's profile into Excel file
    const handleExport = async () => {
        try {
            // Get JWT token from local storage
            const jwtToken = localStorage.getItem('jwtToken');

            // Make a GET request to the backend endpoint that exports user details to Excel
            const response = await axios.get(`${baseURL}/api/export-users-to-excel?userID=${ID}`, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                },
                responseType: 'blob' // Set response type to blob to handle binary data
            });
    
            // Create a URL for the Excel file data
            const url = window.URL.createObjectURL(new Blob([response.data]));
    
            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `user_${ID}.xlsx`); // Set the filename for the downloaded file
            document.body.appendChild(link);
    
            // Click the link to trigger the download
            link.click();
    
            // Remove the link from the document
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting user details:', error);
        }
    };

    useEffect(() => {
        if (ID) {
            fetchDataByID();
        } else {
            fetchData();
        }
    
        // check if isEditing is true in localStorage
        const localIsEditing = localStorage.getItem('isEditing');
        // console.log(`localIsEditing === ${localIsEditing}`);
    
        if (localIsEditing === 'true') {
            setIsEditing(true);
        }
    }, []);

    const handleCancel = () => {
        navigate(-1);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    }

    // Check if chosenCities is fetched
    const chosenCitiesFetched = formData.chosenCities;
    // Check if chosenEmployers is fetched
    const chosenEmployersFetched = formData.chosenEmployers;

    return ( 
        <div>
            <div className="buttonTotal">
                {ID && (
                    <div className='back-button' onClick={handleCancel}>
                        <img id='back-icon' src='/images/back.png' alt="Back" />
                    </div>
                )}
                {ID && (
                    // <img className='export-images' src="images/export_icon.png" alt="Export All Users" onClick={handleExport} /> 
                    <span className="event-icon-info">
                        <img
                            className="export-images"
                            src="images/export_icon.png"
                            alt="Export User"
                            onClick={handleExport}
                        />
                        <span className="tooltip">Export User</span>
                    </span>
                )}
            </div>
            <div className='profile-edit-div'>
                <div className='profile-container'>
                    <div className='profile-title'>Personal Information {isAdmin === true && <div className='admin-tag'>Admin</div>}</div>
                    <div className='userEmail'>Email/电子邮件：{Email}</div>
                    <div className='userID'>userID: {userID}</div>
                    <div className='userInfo-container'>
                        <div className='userInfo' id='username'>User Name/用户名</div>
                        <input className='userAnswer' type='text' maxLength={maxLengths.userName} onChange={handleChange} name='userName' id='userName' defaultValue={userName} placeholder={userName} readOnly={!isEditing}></input>
                        {!isUserNameAvailable && <div className="error-msg">{NameErrorMsg}</div>}
                    </div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>Full Name/姓名</div>
                        <input className='userAnswer' type="text" maxLength={maxLengths.fullName} onChange={handleChange} name='fullName' id="fullName" defaultValue={fullName} placeholder={fullName} readOnly={!isEditing}/>
                    </div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>Gender/性别</div>
                            <select className='userAnswer' onChange={(e) => handleGender(e)} name='gender' id="gender" value={gender} disabled={!isEditing}>
                                <option value="">--Select Option--</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                    </div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>Date of Birth/出生日期</div>
                        <input className='userAnswer' type="date" onChange={handleTimeChange} name='DOB' id="DOB" value={DOB} readOnly={!isEditing} max={new Date().toISOString().split('T')[0]}/>
                    </div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>Nationality/国籍</div>
                            {/* <select className='userAnswer' onChange={(e) => handleNationality(e)} name='nationality' id="nationality" value={nationality} disabled={!isEditing}>
                                <option value="">--Select Option--</option>
                                <option value="Chinese">Chinese</option>
                                <option value="Australian">Australian</option>
                                <option value="Other">Other</option>
                            </select>
                            {
                                nationality === "Other" ?
                                <input className='userAnswer' type="text" onChange={handleNationality} name='nationality' id="nationality" defaultValue={nationality} placeholder={nationality} readOnly={!isEditing}/>
                                :
                                null
                            } */}
                            <input className='userAnswer' type="text" onChange={handleNationality} name='nationality' id="nationality" defaultValue={nationality} placeholder={nationality} readOnly={!isEditing}/>
                    </div>
                    
                    <div className='userInfo-container'>
                        <div className='userInfo'>Phone/手机</div>
                        <input className='userAnswer' type="tel" maxLength={maxLengths.phoneNumber} onChange={handlePhoneChange} name='phoneNumber' id="phone" placeholder={phoneNumber} value={phoneNumber} readOnly={!isEditing}/>  
                    </div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>WeChat ID/微信号</div>
                        <input className='userAnswer' type="text" maxLength={maxLengths.WeChatID} onChange={handleChange} name='WeChatID' id="WeChatID" defaultValue={WeChatID} placeholder={WeChatID} readOnly={!isEditing}/>
                    </div>
                </div>

                <div className='profile-container'>
                    <div className='profile-title'>Academic Background</div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>Graduate School/本科毕业学校</div>
                        <input className='userAnswer' type="text" maxLength={maxLengths.graduateSchool} onChange={handleChange} name='graduateSchool' id="graduateSchool" defaultValue={graduateSchool} placeholder={graduateSchool} readOnly={!isEditing}/>
                    </div>
                    <div className='userInfo-container' style={{ position: 'relative' }}>
                        <div className='userInfo'>Degree/学位信息</div>
                        <input className='userAnswer' type="text" maxLength={maxLengths.degreeInfo} onChange={handleChange} name='degreeInfo' id="degreeInfo" defaultValue={degreeInfo} placeholder={degreeInfo} readOnly={!isEditing}/>
                    </div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>Discipline/学科专业</div>
                        <input className='userAnswer' type="text" maxLength={maxLengths.courseMajor} onChange={handleChange} name='courseMajor' id="courseMajor" defaultValue={courseMajor} placeholder={courseMajor} readOnly={!isEditing}/>
                    </div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>Faculty/毕业院校</div>
                        <select className='userAnswer' disabled={!isEditing} value={faculty} onChange={(e) => handleFaculty(e)}>
                            <option value="None">None</option>
                            <option value="Architecture, Building and Planning">Faculty of Architecture, Building and Planning</option>
                            <option value="Arts">Faculty of Arts</option>
                            <option value="Business and Economics">Faculty of Business and Economics</option>
                            <option value="Education">Faculty of Education</option>
                            <option value="Engineering">Faculty of Engineering</option>
                            <option value="Medicine and Health">Faculty of Medicine and Health</option>
                            <option value="Science">Faculty of Science</option>
                            <option value="Law">Faculty of Law</option>
                        </select>
                    </div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>Faculty Advisor/指导教授</div>
                        <input className='userAnswer' type="text" maxLength={maxLengths.advisor} onChange={handleChange} name='advisor' id="advisor" defaultValue={advisor} placeholder={advisor} readOnly={!isEditing}/>
                    </div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>Graduate Time/毕业时间（预计毕业时间）</div>
                        <input className='userAnswer' type="date" onChange={handleTimeChange} name='graduateTime' id="graduateTime" value={graduateTime} readOnly={!isEditing} min={new Date().toISOString().split('T')[0]}/>
                    </div>
                    <div className='userInfo-container-research'>
                        <div className='userInfo-research'>Research direction and achievements/在校研究方向和主要成果</div>
                        <textarea className='userAnswer-research' type="text" maxLength={maxLengths.researchAchievement} onChange={handleChange} name='researchAchievement' id="researchAchievement" defaultValue={researchAchievement} placeholder={researchAchievement} readOnly={!isEditing} />
                    </div>
                </div>

                <div className='profile-container'>
                    <div className='profile-title'>Repatriation demand/回国城市要求</div>
                    {/* {console.log(formData)} */}
                    {
                        chosenCitiesFetched ? (
                            <MultiSelectCity pca={pca} isEditing={isEditing} formData={formData} setFormData={setFormData} />
                        ) : (
                            <p>Loading cities...</p>
                        )
                    }
                    {
                        chosenEmployersFetched ? (
                            <MultiSelectEmployer isEditing={isEditing} formData={formData} setFormData={setFormData} />
                        ) : (
                            <p>Loading employers...</p>
                        )
                    }
                    <div className='userInfo-container'>
                        <div className='userInfo'>Expected Salary/期望年薪 (AUD)</div>
                        <select className='userAnswer' disabled={!isEditing} value={expectSalary} onChange={(e) => handleSalary(e)}>
                            <option value="Less $30,000">Less $30,000</option>
                            <option value="$30,000-$60,000">$30,000-$60,000</option>
                            <option value="$60,000-$90,000">$60,000-$90,000</option>
                            <option value="$90,000-$120,000">$90,000-$120,000</option>
                            <option value="$120000+">$120000+</option>
                        </select>                    
                    </div>
                    <div className='userInfo-container'>
                        <div className='userInfo'>Need any help with repatriation?/是否需要解决回国以及子女国内入学？</div>
                        <select className='userAnswer' onChange={(e) => handleNeedHelpChange(e)} disabled={!isEditing} value={needHelp}>
                            <option value="false">NO/否</option>
                            <option value="true">YES/是</option>
                        </select>
                    </div>
                </div>
                
                <div>
                    <div className='buttonTotal'>
                        {isEditing ? (
                            <>
                                <button className='cta-button' id='saveButton' onClick={handleSave}>Save</button>
                                <button className='cta-button' id='editButton' onClick={handleCancelEdit}>Cancel</button>
                            </>
                        ) : (
                            <>
                                <button className='cta-button' id='saveButton' disabled>Save</button>
                                <button className='cta-button' id='editButton' onClick={handleEdit}>Edit</button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

Profile.propTypes = {

}

export default WithAuthCheck(Profile);